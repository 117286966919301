@use "./variables";
@import "../../angular-cd/dist/angular-cd-library/angular-cd-library.styles.scss";

html,
body {
  height: 100%;
}

code {
  margin: 0 1px;
  padding: 0.2em 0.4em;
  font-size: 0.9em;
  background: #f2f4f5;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}

.component__page-header {
  display: flex;
  gap: 1rem;

  rd-button {
    margin-left: auto;
  }
}

.component__title {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
}

.component__description {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 150%;
}

.page-title {
  font-size: 24px;
  line-height: 32px;
  color: variables.$zs-gray-100;
}

.section-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: variables.$zs-gray-100;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #efefef;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #87848d;
}
